<template>
  <div id="businessCircle-parkingTime">
    <ul>
      <li v-for="item of list" :key="item.timePoint">
        <span>{{ item.timePoint }}</span>
        <el-progress :percentage="item.value" :stroke-width="strokeWidth"></el-progress>
      </li>
    </ul>
  </div>
</template>

<script>
import {formatRatio} from "@/utils/util"

export default {
  data() {
    return {
      strokeWidth: 0,
      list: []
    }
  },
  created() {
    this.getList()
    this.$nextTick(() => {
      this.strokeWidth = document.documentElement.clientWidth / 1920 * 16 * 0.7
    })
  },
  methods: {
    async getList(params = {}) {
      try {
        let res = await this.$http.post("/statistics/parking/duration", params)
        if (res.code === 0) {
          this.list = [
            {
              timePoint: "1小时以内",
              value: res.data ? formatRatio(res.data.lessOneHour) : 0
            },
            {
              timePoint: "1-4小时",
              value: res.data ? formatRatio(res.data.oneToFourHour) : 0
            },
            {
              timePoint: "4-12小时",
              value: res.data ? formatRatio(res.data.fourToTwelveHour) : 0
            },
            {
              timePoint: "12小时以上",
              value: res.data ? formatRatio(res.data.moreThanTwelveHour) : 0
            }
          ]
        } else {
          this.$message.error(res.msg)
        }
      } catch (err) {
        console.log(err)
        this.$message.error("停车时长偏好分析获取失败")
      }
    },
  }
}
</script>

<style lang="scss">
#businessCircle-parkingTime {
  height: calc(100% - 56px);
  display: flex;

  & > ul {
    width: 100%;
    align-self: center;

    & > li {
      display: flex;
      justify-content: space-between;
      align-content: center;
      margin-bottom: 3rem;

      &:last-child {
        margin-bottom: 0;
      }

      .el-progress {
        width: calc(100% - 8rem);
        .el-progress__text {
          margin-left: 1rem;
          color: white;
        }
      }

      span {
        display: inline-block;
        width: 7rem;
        text-align: right;
        font-size: 0.9rem;
        color: white;
      }
    }
  }
}
</style>
